import React from "react"
import { Carousel } from "react-responsive-carousel"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { Section, SectionWrapper, SubTitle, Text } from "../core/commonExports"
import { ReviewsContainer, ReviewCard, ReviewHeader, ReviewMetaInfo, ReviewerProfilePicture, ReviewText } from "../campaigns/glp9/ReviewSectionStyles"
import { reviewData } from "../campaigns/glp9/ReviewsSection"

const ReviewItem = ({reviewData = {}}) => {
  const {info, reviewer, reviewerDP, businessImg, reviewText, business} = reviewData

  const fiveStars = (
    <div style={{width: "78px", height: "14px"}}>
      <StaticImage 
        src="../../../assets/images/campaigns/review-imgs/google-review-5-stars.webp"
        alt="5-stars"
        loading="lazy"
        placeholder="blurred"
      />
    </div>
  )

  const reviewMetaInfo = (
    <ReviewMetaInfo>
      <ReviewerProfilePicture>
        {reviewerDP}
      </ReviewerProfilePicture>
      <section>
        <section style={{display: "flex", gap: "1rem"}}>
          <Text fontSize="16px" mfontSize="13px" lineHeight="17px" mlineHeight="15px" fontWeight="500" color="rgba(0, 0, 0, 0.87)" style={{margin: "0", marginRight: "0.25rem"}}>{reviewer}</Text>
          {/* <OnDesktop style={{width: "auto"}}>{fiveStars}</OnDesktop> */}
        </section>
        <Text fontSize="14px" mfontSize="12px" lineHeight="24px" mlineHeight="15px" color="#000" fontWeight="600" desktopStyles={{margin: '0'}} mobileStyles={{marginTop: "2px", marginBottom: "6px"}} style={{ textAlign: "left", fontStyle: "italic"}}>
          {business}
        </Text>
        <Text fontSize="14px" mfontSize="12px" lineHeight="19px" mlineHeight="15px" color="rgba(0, 0, 0, 0.54)" fontWeight="400" style={{ margin: "0", display: "flex", textAlign: "left"}}>
          {info}
        </Text>
      </section>
    </ReviewMetaInfo>
  )

  const googleLogo = (
    <div style={{width: "24px", height: "24px"}}>
      <StaticImage 
        src="../../../assets/images/campaigns/review-imgs/google.webp"
        alt="google-review"
        loading="lazy"
        placeholder="blurred"
      />
    </div>
  )

  const reviewTextContainer = (
    <StyledReviewText>
      {reviewText}
    </StyledReviewText>
  )

  return (
    <StyledReviewCard>
      <BusinessImgContainer>
        {businessImg}
      </BusinessImgContainer>
      <div>
        {/* <OnMobile>
          <div style={{width: "min(260px, 100%)", height: "142px"}}>
            {businessImg}
          </div>
        </OnMobile> */}
        <div className="d-flex" style={{gap: "12px", alignItems: "center"}}>
          {googleLogo}
          {fiveStars}
        </div>
        {reviewTextContainer}
        
        <ReviewHeader>
          {reviewMetaInfo}
        </ReviewHeader>
      </div>
    </StyledReviewCard>
  )
}

const TestimonialsSection = () => {
  return (
    <Section background="rgba(239, 242, 255, 1)" style={{position: "relative"}}>
      <SectionWrapper  mobileStyles={{padding: "24px 0", paddingTop: "28px"}} desktopStyles={{paddingTop: "40px"}}>
        <SubTitle fontSize="36px" mfontSize="24px" style={{ marginBottom: '40px', textAlign: "center"}}>
          Our customers love us
        </SubTitle>
        <ReviewsContainer>
          <Carousel centerMode infiniteLoop>
            {reviewData.map(review => <ReviewItem reviewData={review} />)}
          </Carousel>
        </ReviewsContainer>
      </SectionWrapper>
    </Section>
  )
}

const BusinessImgContainer = styled.div`
  width: min(238px, 100%); 
  height: 306px;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    width: min(260px, 100%); 
    height: 142px;
  }
`

const StyledReviewText = styled(ReviewText)`
  margin: 16px 0;
`

const StyledReviewCard = styled(ReviewCard)`
  display: flex;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`

export default TestimonialsSection